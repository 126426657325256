import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://node.skilledsolutions.co.uk/api/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
  sendForgotEmail(data) {
    return axios.post(API_URL + 'auth/forgotEmail', data , { headers: authHeader() });
  }
  getUsers() {
    return axios.get(API_URL + 'sm/users', { headers: authHeader() });
  }
  updateUsers(data) {
    return axios.post(API_URL + 'sm/updateUser', data, { headers: authHeader() });
  }
  deleteUser(data) {
    return axios.post(API_URL + 'sm/deleteUser', data, { headers: authHeader() });
  }
  updateRole(data) {
    return axios.post(API_URL + 'sm/updateRole', data, { headers: authHeader() });
  }
  getUserDetails() {
    return axios.get(API_URL + 'associates/userDetails', { headers: authHeader() });
  }
  postTicket(data) {
    return axios.post(API_URL + 'ticket/postTicket', data , { headers: authHeader() });
  }
  getUserTickets(data) {
    return axios.post(API_URL + 'ticket/getUserTickets', data , { headers: authHeader() });
  }
  getDepartmentTickets(data) {
    return axios.post(API_URL + 'ticket/getDepartmnetTickets', data , { headers: authHeader() });
  }
  sendMessage(data) {
    return axios.post(API_URL + 'ticket/postMessage', data , { headers: authHeader() });
  }
  getMessages(data) {
    return axios.post(API_URL + 'ticket/getMessages', data , { headers: authHeader() });
  }
  countUnseen(data) {
    return axios.post(API_URL + 'ticket/unseen', data , { headers: authHeader() });
  }
  countUnseenDep(data) {
    return axios.post(API_URL + 'ticket/unseenDep', data , { headers: authHeader() });
  }
  markMessages(data) {
    return axios.post(API_URL + 'ticket/markSeen', data , { headers: authHeader() });
  }
  closeTicket(data) {
    return axios.post(API_URL + 'ticket/closeTicket', data , { headers: authHeader() });
  }
  getProfilesData() {
    return axios.get(API_URL + 'associates/profilesData', { headers: authHeader() });
  }
  getProfileData(data) {
    return axios.post(API_URL + 'associates/profileData',data, { headers: authHeader() });
  }
  getContactNames() {
    return axios.get(API_URL + 'associates/contactNames', { headers: authHeader() });
  }
  updateBasicInfo(data) {
    return axios.post(API_URL + 'associates/updateBasicInfo',data, { headers: authHeader() });
  }
  getAuditData() {
    return axios.get(API_URL + 'compliance/auditData', { headers: authHeader() });
  }
  updateDl(data) {
    return axios.post(API_URL + 'associates/updateDlData',data, { headers: authHeader() });
  }
  updateRtw(data) {
    return axios.post(API_URL + 'associates/updateRtwData',data, { headers: authHeader() });
  }
  getOnboardedNames() {
    return axios.get(API_URL + 'compliance/onboardedNames', { headers: authHeader() });
  }
  getAddress(data) {
    return axios.post(API_URL + 'compliance/address',data, { headers: authHeader() });
  }
  postIncidentForm(data) {
    return axios.post(API_URL + 'compliance/incidentForm',data, { headers: authHeader() });
  }
  getDepotList() {
    return axios.get(API_URL + 'kpi/depotList', { headers: authHeader() });
  }
  insertDepot(data) {
    return axios.post(API_URL + 'kpi/insertDepot',data, { headers: authHeader() });
  }
  deleteDepot(data) {
    return axios.post(API_URL + 'kpi/deleteDepot',data, { headers: authHeader() });
  }
  depotScorecards(data) {
    return axios.post(API_URL + 'kpi/depotScorecards',data, { headers: authHeader() });
  }
  pmUpdate(data) {
    return axios.post(API_URL + 'osmTools/pmUpdate',data, { headers: authHeader() });
  }
  sdUpdate(data) {
    return axios.post(API_URL + 'osmTools/sdUpdate',data, { headers: authHeader() });
  }
  getPmsdValidation() {
    return axios.get(API_URL + 'osmTools/pmsdValidation', { headers: authHeader() });
  }
  getStatisticData(data) {
    return axios.post(API_URL + 'osmTools/statisticsData',data, { headers: authHeader() });
  }
  getChannels() {
    return axios.get(API_URL + 'osmTools/channels', { headers: authHeader() });
  }
  insertChannel(data) {
    return axios.post(API_URL + 'osmTools/insertChannel',data, { headers: authHeader() });
  }
  deleteChannel(data) {
    return axios.post(API_URL + 'osmTools/deleteChannel',data, { headers: authHeader() });
  }
  sendMessageChannels(data) {
    return axios.post(API_URL + 'osmTools/sendMessages',data, { headers: authHeader() });
  }
  getIncidents() {
    return axios.get(API_URL + 'compliance/incidents', { headers: authHeader() });
  }
  getArchiveData() {
    return axios.get(API_URL + 'associates/archiveData', { headers: authHeader() });
  }
  updateArchive(data) {
    return axios.post(API_URL + 'associates/updateArchive',data, { headers: authHeader() });
  }
  getApplicationData() {
    return axios.get(API_URL + 'recruitment/getApplicationData', { headers: authHeader() });
  }
  getOnboardingData() {
    return axios.get(API_URL + 'recruitment/getOnboardingData', { headers: authHeader() });
  }
  updateApplicationData(data) {
    return axios.post(API_URL + 'recruitment/updateApplicationData',data, { headers: authHeader() });
  }
  updateOnboardingData(data) {
    return axios.post(API_URL + 'recruitment/updateOnboardingData',data, { headers: authHeader() });
  }
  getStations() {
    return axios.get(API_URL + 'recruitment/getStations', { headers: authHeader() });
  }
  getCaptureData() {
    return axios.get(API_URL + 'recruitment/getCaptureData', { headers: authHeader() });
  }
  getAdd() {
    return axios.get(API_URL + 'recruitment/getAdd', { headers: authHeader() });
  }
  sendDataCapture(data) {
    return axios.post(API_URL + 'recruitment/sendDataCapture',data, { headers: authHeader() });
  }
  getFiles(data) {
    return axios.post(API_URL + 'associates/files',data, { headers: authHeader() });
  }
  getPolicies() {
    return axios.get(API_URL + 'compliance/getPolicies', { headers: authHeader() });
  }
  getDashData(data) {
    return axios.post(API_URL + 'dash/dashData',data, { headers: authHeader() });
  }
  updatePDetails(data) {
    return axios.post(API_URL + 'dash/updatePDetails',data, { headers: authHeader() });
  }
  importPdf(data) {
    return axios.post(API_URL + 'associates/importPdf',data, { headers: authHeader() });
  }
  getInterviews(data) {
    return axios.post(API_URL + 'ad/interviews',data, { headers: authHeader() });
  }
  sendSlots(data) {
    return axios.post(API_URL + 'ad/sendSlots',data, { headers: authHeader() });
  }
  apply(data) {
    return axios.post(API_URL + 'ad/apply',data, { headers: authHeader() });
  }
  sendNoti(data) {
    return axios.post(API_URL + 'user/notiSend',data, { headers: authHeader() });
  }
  markCreated(data) {
    return axios.post(API_URL + 'user/created',data, { headers: authHeader() });
  }
  sendDataCaptureNotiEmail(data) {
    return axios.post(API_URL + 'recruitment/notiEmail',data, { headers: authHeader() });
  }
  getDebtors() {
    return axios.get(API_URL + 'finance/getDebtors', { headers: authHeader() });
  }
  updateDebtors(data) {
    return axios.post(API_URL + 'finance/updateDebtors',data, { headers: authHeader() });
  }
  createDebtors(data) {
    return axios.post(API_URL + 'finance/createDebtors',data, { headers: authHeader() });
  }
  getArchiveNames() {
    return axios.get(API_URL + 'finance/getArchiveNames', { headers: authHeader() });
  }
}

export default new UserService();
